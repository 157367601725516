<template>
  <div class="relay-service-page app-container">
    <div class="card-container">
      <div class="title-line">
        <span class="title-label"> 中继服务配置 </span>
        <span class="title-tips">
          <i class="el-icon-info" />
          用户配置的中继相关信息
        </span>
      </div>
      <div class="form-container">
        <el-form
          v-loading="loading"
          :model="serviceForm"
          :rules="rules"
          label-position="top"
          ref="serviceForm"
          label-width="100px"
        >
          <el-row :gutter="48">
            <el-col :span="8">
              <el-form-item label="源IP" prop="sourceIp">
                <el-input
                  v-model="serviceForm.sourceIp"
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="源端口" prop="sourcePort">
                <el-input
                  @input="(value) => inputChange(value, 'sourcePort')"
                  v-model="serviceForm.sourcePort"
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="源从站ID" prop="sourceSlaveId">
                <el-input
                  v-model="serviceForm.sourceSlaveId"
                  @input="(value) => inputChange(value, 'sourceSlaveId')"
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="目标IP" prop="targetIp">
                <el-input
                  v-model="serviceForm.targetIp"
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="目标端口" prop="targetPort">
                <el-input
                  @input="(value) => inputChange(value, 'targetPort')"
                  v-model="serviceForm.targetPort"
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="源从站ID" prop="targetSlaveId">
                <el-input
                  @input="(value) => inputChange(value, 'targetSlaveId')"
                  v-model="serviceForm.targetSlaveId"
                  :placeholder="$t('commons.pleaseInput')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="映射关系" prop="relationshipList">
                <el-row
                  style="margin-bottom: 10px"
                  :gutter="12"
                  v-for="(item, index) in serviceForm.relationshipList"
                  :key="index"
                >
                  <el-col :span="6">
                    <el-input
                      @input="(value) => inputChange(value, 'source', index)"
                      v-model="item.source"
                      placeholder="源地址"
                    ></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-input
                      @input="(value) => inputChange(value, 'target', index)"
                      v-model="item.target"
                      placeholder="目标地址"
                    ></el-input>
                  </el-col>
                  <el-col :span="6">
                    <el-input
                      @input="(value) => inputChange(value, 'length', index)"
                      v-model="item.length"
                      placeholder="映射长度"
                    ></el-input>
                  </el-col>
                  <el-col
                    :span="4"
                    v-if="serviceForm.relationshipList.length > 1"
                  >
                    <r-button type="danger" @click="deleteSingle(index)"
                      >删除</r-button
                    >
                  </el-col>
                </el-row>
              </el-form-item>
              <div class="add-button">
                <r-button @click="addNewLine">添加</r-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="save-button">
      <r-button plain :loading="loading" @click="submitHandler">保存</r-button>
    </div>
  </div>
</template>

<script>
import {
  getRelayServiceInfo,
  updateRelayServiceInfo,
} from "@/api/ruge/vlink/device/device";
import { ipValidate } from "@/utils/utils.js";
export default {
  name: "relay-service-page",
  data() {
    return {
      loading: false,
      serviceForm: {
        sourceIp: null,
        sourcePort: null,
        sourceSlaveId: null,
        targetIp: null,
        targetPort: null,
        targetSlaveId: null,
        // repeaterMap: {}
        relationshipList: [
          {
            source: null,
            target: null,
            length: null,
          },
        ],
      },
      rules: {
        relationshipList: [
          {
            required: true,
            validator: this.checkRelationshipList,
            trigger: ["change", "blur"],
          },
        ],
        sourceIp: [
          { required: true, validator: ipValidate, trigger: "change" },
        ],
        sourcePort: [
          { required: true, message: "请输入源端口", trigger: "change" },
        ],
        sourceSlaveId: [
          { required: true, message: "请输入源从站ID", trigger: "change" },
        ],
        targetIp: [
          { required: true, validator: ipValidate, trigger: "change" },
        ],
        targetPort: [
          { required: true, message: "请输入目标端口", trigger: "change" },
        ],
        targetSlaveId: [
          { required: true, message: "请输入源从站ID", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    inputChange(value, prop, index) {
      if (index !== undefined) {
        this.serviceForm.relationshipList[index][prop] = value.replace(
          /[^0-9]/g,
          ""
        );
        return;
      }
      this.serviceForm[prop] = value.replace(/[^0-9]/g, "");
    },
    initDatas() {
      getRelayServiceInfo().then((res) => {
        if (res) {
          const {
            sourceIp,
            sourcePort,
            sourceSlaveId,
            targetIp,
            targetPort,
            targetSlaveId,
            repeaterMap,
          } = res;
          let relationshipList = [];
          for (let item in repeaterMap) {
            const [source, target] = item.split("-");
            const length = repeaterMap[item];
            relationshipList.push({
              source,
              target,
              length,
            });
          }
          this.serviceForm = {
            sourceIp,
            sourcePort,
            sourceSlaveId,
            targetIp,
            targetPort,
            targetSlaveId,
            relationshipList,
          };
        }
      });
    },
    buildParasm() {
      const repeaterMap = {};
      this.serviceForm.relationshipList.forEach((ele) => {
        const { source, target, length } = ele;
        repeaterMap[`${source}-${target}`] = length;
      });
      return {
        sourceIp: this.serviceForm.sourceIp,
        sourcePort: this.serviceForm.sourcePort,
        sourceSlaveId: this.serviceForm.sourceSlaveId,
        targetIp: this.serviceForm.targetIp,
        targetPort: this.serviceForm.targetPort,
        targetSlaveId: this.serviceForm.targetSlaveId,
        repeaterMap,
      };
    },
    submitHandler() {
      this.$refs.serviceForm.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        updateRelayServiceInfo(this.buildParasm())
          .then(() => {
            this.$message.success("保存成功！");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    deleteSingle(index) {
      this.serviceForm.relationshipList.splice(index, 1);
      this.$refs.serviceForm.validate();
    },
    checkRelationshipList(rule, value, callback) {
      for (let item of this.serviceForm.relationshipList) {
        const { length, source, target } = item;
        if (!length || !source || !target) {
          callback(new Error("请输入映射关系！"));
        }
      }
      callback();
    },
    addNewLine() {
      this.serviceForm.relationshipList.push({
        source: null,
        target: null,
        length: null,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.relay-service-page {
  .card-container {
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 100%;
    .add-button {
      margin-top: 20px;
    }
    .title-line {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .title-label {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .title-tips {
        margin-left: 16px;
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000;
        opacity: 0.7;
        i {
          opacity: inherit;
        }
      }
    }
  }
  .save-button {
    margin-top: 30px;
  }
}
</style>